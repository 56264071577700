/* eslint-disable */
export default {
    allManufacturers: [],
    singleManufacturerUser: {},
    singleManufacturerCategories: [],
    singleManufacturerCities: [],
    singleManufacturerProducts: [],
    singleManufacturerFilteredProducts: [],
    search: [],
    pagination: {}
}